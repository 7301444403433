*
{
    margin: 0;
    padding: 0;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

html,
body
{
    overflow: hidden;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

/*Loading bar
*/
.loader-wrapper{
    z-index: 1;
    width: 100%;
    height:100%;
    position: absolute;
    top:0;
    left: 0;
    background-color:#438dff;
    display:flex;
    justify-content:center;
    align-items: center;
}
.loader-wrapper.fadeOut{
    transition: height 0.5s ease-in-out 0s;
    height: 0;
    /* transition: display 10s;   */
}
.loader.hide{
    transition: display 1s ease-in-out 0s;
    display: none;
    /* transition: display 10s;   */
}
.loader-inner.hide{
    transition: display 1s ease-in-out 0s;
    display: none;
    /* transition: display 10s;   */
}
/* .loader-wrapper.hide{
    transition: display 10s;
    transition: transform 0.3s linear 2s;
    transform: scale(0,0);
    display: none;
} */


.loader {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: relative;
    border: 4px solid #Fff;
    animation: loader 2s infinite ease;
  }

  .loader-inner {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: #fff;
    animation: loader-inner 2s infinite ease-in;
  }

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(180deg);
    }

    50% {
      transform: rotate(180deg);
    }

    75% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes loader-inner {
    0% {
      height: 0%;
    }

    25% {
      height: 0%;
    }

    50% {
      height: 100%;
    }

    75% {
      height: 100%;
    }

    100% {
      height: 0%;
    }
  }

/* Logo img
*/
.logo--img{
    width: 55px;
    position: absolute;
    margin: 40px auto;
    right: 90%;
    /* top: 10px; */
}

/* Burger menu
*/
#nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {

    height: 48px;
    width: 55px;
    transform: scale(0.1,0.1);
    /* transform: translateX(0.2) translateY(0.2); */
    position: absolute;
    /* border: black solid; */
    left:90%;
    /* border: black solid; */
    margin: 40px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}
#nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
    display: block;
    position: absolute;
    height: 8px;
    width: 100%;
    background: #ffffff;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}


#nav-icon2 span {
  display: block;
  position: absolute;
  height: 4px;
  width: 50%;
  background: #ffffff;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#nav-icon2 span:nth-child(odd) {
  left:0px;
  border-radius: 9px 0 0 9px;
}

#nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
  top: 2px;
}

#nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
  top: 17px;
}

#nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
  top: 32px;
}

#nav-icon2.open span:nth-child(1),#nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),#nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
  left: 5px;
  top: 7px;
}

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
  left: 5px;
  top: 29px;
}

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 29px;
}

/* SocialMedia
*/
.handles{
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 120px;

    /* border: black solid; */
    bottom: 0;
    left: 0;
    margin-left: 30px;
    margin-bottom: 30px;
}

.handles >a> i {
    /* margin-left: 20px; */
    color: white;
}
@font-face {
    font-family: PromptFont;
    src: url('/PromptFont.otf') format("opentype");
}
.ride--instruction{
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 30px;
    margin-bottom: 30px;
    font-family: PromptFont;
    color: #ffffff;
    transform: scale(0,0);
    /* border: black solid; */
}
.unride--instruction{
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 30px;
    margin-bottom: 30px;
    font-family: PromptFont;
    color: #ffffff;
    transform: scale(0,0);
}
.unride--instruction.visible{
    transform: scale(1,1);
}
.ride--instruction.visible{
    transform: scale(1,1);
}



/* .loading-bar.ended {
    transform: scaleX(0);
    transform: trans
} */
.point {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -200px;
    margin-left: -350px;
    display: none;
    /* transform: scale(0,0); */
    /* border: black solid; */
    /* transform: translate(-50%, -50%); */
}

.point.visible{
    display: block;
}
.point.visible .point-0--list{
    /* display:block; */
    transform: scale(1,1)
}
/* .point .label {
    position: absolute;
    top: -20px;
    left: -20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ff000077;
    color: #ffffff;
    font-family: Helvetica, Arial, sans-serif;
    text-align: center;
    line-height: 40px;
    font-weight: 100;
    font-size: 14px;
    transform: scale(0,0);
    transition: transform 0.3s;
} */
.point-0--list {
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    width: 700px;
    /* position: absolute;
    top: 50%;
    left: 50%; */
    /* margin-top: -20px;
    margin-left: -20px; */
    /* transform: translate(-50%, -50%); */
    /* position: absolute;
    top: 50%;
    left: 50%; */
    /* width:40px;
    width:40px; */
    /* width: 600px;
    height: 400px; */
    /* border: black solid; */
    /* float: left; */
    /* transform: translate(-50%, -50%); */
    /* border-radius: 50%; */
    /* background: #ff000077; */
    color: #ffffff;
    font-family: Helvetica, Arial, sans-serif;
    text-align: center;
    line-height: 40px;
    font-weight: 100;
    font-size: 14px;
    transform: scale(0,0);
    transition: transform 0.3s;
    /* counter */
    counter-reset: list-number -1;
}
.point-0--text{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 100px;
    font-weight: 400;
    color:#ffffff;
    /* color: rgb(0, 0, 0); */
    margin-bottom: 60px;
    /* font-stretch: expanded; */
    display: inline-block;
    height: auto;
    letter-spacing: 10px;
    transition: all 0.2s ease;
}
.point-0--text:hover{
    /* font-family: 'Josefin Sans', sans-serif;
    font-size: 100px;
    font-weight: 400;
    color: rgb(0, 0, 0);
    margin-bottom: 50px; */
    letter-spacing: 0;
    transition: all 0.2s ease;
    cursor: pointer
}
.point-0--text:before{
    counter-increment: list-number;
    content: counter(list-number);
    font-size: 20px;
    margin-right: 10px;
}


